import http from "./http-common";
let token = sessionStorage.getItem("authToken");

const getDevotional = (data) => {
  return http.get(`api/devotionals/${data}`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};

const getLatestDevotional = (data) => {
  return http.get(`/api/devotionals/get/latest_email`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};

const deleteJob = (data) => {
  return http.delete(`api/career/delete/${data}`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};

const deleteMessage = (data) => {
  return http.delete(`/api/admin/contact_us/delete/${data}`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};
const getDashBoardData = () => {
  return http.get("api/admin/summary", {
    headers: {
      Authorization: `${token}`,
    },
  });
};
//EVENTS
const deleteEvents = (data) => {
  return http.delete(`/api/events/remove/${data}`);
};
//DEVOTIONAL
const deleteDevotional = (data) => {
  return http.delete(`/api/devotionals/remove/${data}`);
};
//NEWS
const deleteNews = (data) => {
  return http.delete(`/api/news/remove/${data}`);
};
//PRESS RELEASE
const deletePress = (data) => {
  return http.delete(`/api/pressrelease/remove/${data}`);
};
//leaders
const deleteLeaders = (data) => {
  return http.delete(`/api/churchleader/remove/${data}`);
};
const getAllLeaders = (page = 1, limit = 10) => {
  return http.get("/api/churchleader");
};

const editLeaders = (data, full_name) => {
  const formData = {
    full_name
  }
  return http.put(`/api/churchleader/update/${data}`,
    JSON.stringify(formData)
  );
};
const editDevotional = (data, formData) => {
  return http.put(`/api/devotionals/update/${data}`,
    JSON.stringify(formData)
  );
};

//LOCATIONS
const getAllLocations = () => {
  return http.get("/api/locations");
};

const deleteLocation = (data) => {
  return http.delete(`/api/locations/remove/${data}`);
};

const editLocation = (data, full_name) => {
  const formData = {
    full_name
  }
  return http.put(`/api/churchleader/update/${data}`,
    JSON.stringify(formData)
  );
};


const getAllTransaction = () => {
  return http.get(`api/admin/transaction`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};
const deleteFaq = (data) => {
  return http.delete(`api/faq/${data}`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};



const verifyUser = (data) => {
  return http.post("api/admin/verify_email", data);
};

const sendDevotionalUpdate = (data) => {
  return http.post("api/devotionals/send/email", data);
};
const logout = (data) => {
  return http.delete(`api/admin/logout`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};

const exportedObject = {
  sendDevotionalUpdate,
  editDevotional,
  deleteDevotional,
  deleteEvents,
  deleteNews,
  deletePress,
  getLatestDevotional,
  deleteFaq,
  deleteLeaders,
  editLeaders,
  getAllLocations,
  deleteLocation,
  editLocation,
  logout,
  getDashBoardData,
  getAllLeaders,
  getAllTransaction,
  getDevotional,
  verifyUser,
  deleteMessage,
  deleteJob
};

export default exportedObject;
