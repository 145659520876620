import { Box, SimpleGrid } from "@chakra-ui/react";
import ComplexTable from "views/admin/leaders/components/ComplexTable";
import React, { useEffect } from "react";
import Swal from "sweetalert2";
import Spinner from "components/Spinner";
import AllService from "../../../services/index";

export default function Settings() {
  const user = sessionStorage.getItem("user");
  if (!user) window.location.href = "/admin/default#/auth/sign-in";

  const [tableData, setTableData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    AllService.getAllLeaders()
      .then((res) => {
        console.log(res);
        if (res.data.status === 200) {
          setTableData(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        Swal.fire({
          title: "Error!",
          text: "An error occured: " + e.response.data.msg,
          icon: "error",
          confirmButtonText: "ok",
          confirmButtonColor: "#08AC04",
        });
      });
  }, []);
  const tableheader = [
    {
      Header: "Fullname",
      accessor: "full_name",
    },
    {
      Header: "createdAt",
      accessor: "createdAt",
    },
    {
      Header: "updatedAt",
      accessor: "updatedAt",
    },
    {
      Header: "More",
      accessor: "_id",
    },
  ];

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
          <SimpleGrid
            mb="20px"
            columns={{ sm: 1, md: 1 }}
            spacing={{ base: "20px", xl: "20px" }}
          >
            <ComplexTable columnsData={tableheader} tableData={tableData} />
          </SimpleGrid>
        </Box>
      )}
    </>
  );
}
