// chakra imports
import { Box, Flex, Stack, Button } from "@chakra-ui/react";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import React from "react";
import AllService from "../../../services/index";
import Swal from "sweetalert2";
// FUNCTIONS

function SidebarContent(props) {
  const { routes } = props;
  const logout = () => {
    setTimeout(
      Swal.fire({
        title: "Logout Successful",
        width: 600,
        padding: "3em",
        icon: "success",
        color: "#87AA62",
        background: "#fff ",
        confirmButtonColor: "#08AC04",
        backdrop: `
                  #87AA62
                  left top
                  no-repeat
                `,
      }),
      100000
    );
    sessionStorage.clear();
    window.location.href = "/auth/sign-in";
  };
  // SIDEBAR
  return (
    <Flex direction="column" height="100%" pt="25px" borderRadius="30px">
      <Brand />
      <Stack direction="column" mb="auto" mt="8px">
        <Box ps="20px" pe={{ md: "16px", "2xl": "1px" }}>
          <Links routes={routes} />
        </Box>
      </Stack>

      <Box
        ps="20px"
        pe={{ md: "16px", "2xl": "0px" }}
        mt="60px"
        mb="40px"
        borderRadius="30px"
      >
        <Button
          bg="red"
          _hover={{ bg: "blue" }}
          _active={{ bg: "whiteAlpha.100" }}
          mb={{ sm: "16px", xl: "24px" }}
          color={"white"}
          fontWeight="regular"
          fontSize="sm"
          minW="185px"
          mx="auto"
          onClick={logout}
        >
          Logout
        </Button>
      </Box>
    </Flex>
  );
}

export default SidebarContent;
