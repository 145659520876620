import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Text,
  useColorModeValue,
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Icon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Image,
  SimpleGrid,
  Select,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";
import React, { useMemo, useRef, useState, useEffect } from "react";
import moment from "moment";
// import { NavLink } from "react-router-dom";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";

// Assets
import { MdInfo, MdEdit, MdDateRange, MdEvent, MdCheckCircle } from "react-icons/md";
import { usePostRequestWithFile } from "../../../../mutation";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import AllService from "../../../../services/index";
import Pagination from "../../../../components/Pagination";

import { Editor } from "@tinymce/tinymce-react";

export default function ColumnsTable(props) {

  function getWeekOfYear(dd) {
    if(!data) return false;
    const date = new Date(dd)
    const startOfYear = new Date(date.getFullYear(), 0, 1);
    const diff = date.getTime() - startOfYear.getTime();
    const oneWeekInMilliseconds = 1000 * 60 * 60 * 24 * 7;
    const weekOfYear = Math.floor(diff / oneWeekInMilliseconds) + 1;
    return weekOfYear;
  }
  
  const { columnsData, tableData } = props;

  const [isEdit, setIsEdit] = useState(false);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    state: { pageIndex },
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageCount,
  } = tableInstance;
  initialState.pageSize = 5;

  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  //the alert component
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const [detail, setDetail] = useState("");
  const [isSendmail, setIsSendmail] = useState(false);
  const [mailContent, setMailContent] = useState([]);
  const [mailTitle, setMailTitle] = useState();
  const [editDetail, setEditDetail] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const { createPost, isLoading, errorResp, success } =
    usePostRequestWithFile("api/devotionals/add");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
   
      createPost({
        // photo: file,
        detail: detail,
        title: data.title,
        priorityTag: data.priorityTag,
        tag: data.tag,
        dayInTheWeek: data.dayInTheWeek,
        date: data.date,
        weekOfAYear:getWeekOfYear(data.date)
      });
  };
  const submitEdit = (data) => {
    let file = data?.photo[0];
    if (file?.size > 10242880) {
      Swal.fire({
        title: "Error!",
        text: "File is larger than 10MB",
        icon: "error",
        confirmButtonText: "ok",
        confirmButtonColor: "#08AC04",
      });
    } else {
      setFormData({
        dayInTheWeek: data.dayInTheWeek,
        detail: editDetail,
        priorityTag:data.priorityTag,
        tag:data.tag,
        title:data.title,
        date:data.date,
        weekOfAYear:getWeekOfYear(data.date)
      }) 
      AllService.editDevotional(selectedId, {...data,detail: editDetail ? editDetail : formData.detail})
      .then((res) => {
        if (res.data.status === 200) {
          setTimeout(
            Swal.fire({
              title: "Devotional Updated!",
              width: 600,
              padding: "3em",
              icon: "success",
              color: "#87AA62",
              background: "#fff ",
              confirmButtonColor: "#08AC04",
              backdrop: `
                  #87AA62
                  left top
                  no-repeat
                `,
            }),
            100000
          );
          window.location.reload();
        }
      })
      .catch((e) => {
        Swal.fire({
          title: "Error!",
          text: "An error occured: " + e.response.data.msg,
          icon: "error",
          confirmButtonText: "ok",
          confirmButtonColor: "#08AC04",
        });
      });
    }
  };
  useEffect(() => {
    if (success) {
      setTimeout(
        Swal.fire({
          title: "Devotional Added!",
          width: 600,
          padding: "3em",
          icon: "success",
          color: "#87AA62",
          background: "#fff ",
          confirmButtonColor: "#08AC04",
          backdrop: `
              #87AA62
              left top
              no-repeat
            `,
        }),
        100000
      );
      window.location.reload();
    }
    if (errorResp) {
      console.log(errorResp);
      Swal.fire({
        title: "Error!",
        text: errorResp.errorResponse,
        icon: "error",
        confirmButtonText: "ok",
        confirmButtonColor: "#08AC04",
      });
    }
  }, [success, errorResp]);

  function getDayName(dayNumber) {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    if (dayNumber >= 1 && dayNumber <= 7) {
      return days[dayNumber - 1];
    } else {
      return "Invalid day number";
    }
  }
  // DELETE AN EVENT
  const deleteDevotional = () => {
    AllService.deleteDevotional(selectedId)
      .then((res) => {
        if (res.data.status === 200) {
          setTimeout(
            Swal.fire({
              title: "Devotional Deleted!",
              width: 600,
              padding: "3em",
              icon: "success",
              color: "#87AA62",
              background: "#fff ",
              confirmButtonColor: "#08AC04",
              backdrop: `
                  #87AA62
                  left top
                  no-repeat
                `,
            }),
            100000
          );
          window.location.reload();
        }
      })
      .catch((e) => {
        Swal.fire({
          title: "Error!",
          text: "An error occured: " + e.response.data.msg,
          icon: "error",
          confirmButtonText: "ok",
          confirmButtonColor: "#08AC04",
        });
      });
    onClose();
  };
  const [formData, setFormData] = useState({
    category: "",
    dayInTheWeek: "",
    detail: "",
    phone: "",
    date: "",
    image:"",
    priorityTag:"",
    tag:"",
    title:"",
    weekOfAYear:""
  });
function sendMail(){
  setLoading(true)
  AllService.getLatestDevotional()
  .then((res) => {
    if (res.data.status === 200) {
      setMailContent(res.data.data.content)
      setMailTitle(res.data.data.name)
      setIsSendmail(true)
      onOpen();
      setLoading(false)
    }
  })
  .catch((e) => {
    Swal.fire({
      title: "Error!",
      text: "An error occured: " + e.response.data.msg,
      icon: "error",
      confirmButtonText: "ok",
      confirmButtonColor: "#08AC04",
    });
    setLoading(false)
  });
}
function getDevotional(id){
    AllService.getDevotional(id)
      .then((res) => {
        const devotional = res.data.data
        setFormData({
          category: devotional.category,
          dayInTheWeek: devotional.dayInTheWeek,
          detail: devotional.detail,
          date: devotional.date,
          phone: devotional.phone,
          // photo:devotional.image,
          priorityTag:devotional.priorityTag,
          tag:devotional.tag,
          title:devotional.title,
          weekOfAYear:getWeekOfYear(devotional.date)
        })
        Swal.fire({
          title: "Success!",
          text: "Devotional Details fetched!" ,
          icon: "success",
          confirmButtonText: "ok",
          confirmButtonColor: "#08AC04",
        });
        setIsEdit(true)
      })
      .catch((e) => {
        Swal.fire({
          title: "Error!",
          text: "An error occured: " + e.response.data.msg,
          icon: "error",
          confirmButtonText: "ok",
          confirmButtonColor: "#08AC04",
        });
      });
}
const sendDevotionalUpdate = () => {
  AllService.sendDevotionalUpdate()
    .then((res) => {
      if (res.data.status === 200) {
        setTimeout(
          Swal.fire({
            title: "Devotional Update Sent!",
            width: 600,
            padding: "3em",
            icon: "success",
            color: "#87AA62",
            background: "#fff ",
            confirmButtonColor: "#08AC04",
            backdrop: `
                #87AA62
                left top
                no-repeat
              `,
          }),
          100000
        );
        onClose();
        setIsSendmail(false)
      }
    })
    .catch((e) => {
      Swal.fire({
        title: "Error!",
        text: "An error occured: " + e.response.data.msg,
        icon: "error",
        confirmButtonText: "ok",
        confirmButtonColor: "#08AC04",
      });
    });
  onClose();
};
  //
  return (
    <>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }}
        gap="20px"
        mb="20px"
        mt="40px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdEvent} color={brandColor} />}
            />
          }
          name="Total News"
          value={tableData.length}
          url="#"
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdDateRange} color={brandColor} />
              }
            />
          }
          name="Today's Date"
          // value={moment().format('LLLL')}
          value={moment(tableData[0]?.createdAt).format("LLLL")}
          url="#"
        />
      </SimpleGrid>
      {/* here */}
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" justify="space-between" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Devotional List
          </Text>

          <Button
            fontSize="sm"
            colorScheme="green"
            fontWeight="500"
            w="30%"
            mb="24px"
            type="button"
            onClick={sendMail}
            disabled={loading ? true : false}
          >
            {loading ? "Loading" : "Send Devotional Update"}
          </Button>
        </Flex>
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "image") {
                      data = (
                        <Image
                          src={cell.value}
                          alt={`image ${index}`}
                          boxSize="100px"
                          objectFit="cover"
                        />
                      );
                    } else if (cell.column.Header === "title") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "dayInTheWeek") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {getDayName(cell.value)}
                        </Text>
                      );
                    } else if (cell.column.Header === "More") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          <Button
                            onClick={() => {
                              setSelectedId(cell.value);
                              onOpen();
                            }}
                          >
                            <Icon
                              w="24px"
                              h="24px"
                              me="5px"
                              color={"yellow.400"}
                              as={MdInfo}
                            />
                          </Button>
                          <Button
                            onClick={() => {
                              setSelectedId(cell.value);
                              getDevotional(cell.value);
                            }}
                          >
                            <Icon
                              w="24px"
                              h="24px"
                              me="5px"
                              color={"yellow.400"}
                              as={MdEdit}
                            />
                          </Button>
                        </Text>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor="transparent"
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Pagination
          pageIndex={pageIndex}
          pageCount={pageCount}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
        />
      </Card>

      {/* add a NEW EVENT */}
      {isEdit ? (
        <>
          <Card
            direction="column"
            my="60px"
            w="100%"
            px="0px"
            overflowX={{ sm: "scroll", lg: "hidden" }}
            style={{ padding: "40px 90px", alignContent: "center" }}
            id="editCard"
          >
            <Box me="auto">
              <Heading color={textColor} fontSize="36px" mb="70px">
                Edit Devotional
              </Heading>
            </Box>
            <Flex
              zIndex="2"
              direction="column"
              w="100%"
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "20px", md: "auto" }}
            >
              <form
                onSubmit={handleSubmit(submitEdit)}
                method="POST"
                encType="multipart/form-data"
              >
                <FormControl>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    <Text size={"xl"}>Enter a Title</Text>
                  </FormLabel>
                  <Input
                    style={{ border: "solid 1px blue" }}
                    variant="auth"
                    fontSize="sm"
                    width="100%"
                    ms={{ base: "0px", md: "0px" }}
                    type="text"
                    mb="12px"
                    defaultValue={formData.title}
                    {...register("title", {})}
                  />
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    <Text size={"xl"}>Details</Text>
                  </FormLabel>
                  <div>
                    <Editor
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                      apiKey={process.env.REACT_APP_TINY}
                      initialValue={formData.detail}
                      init={{
                        height: 500,
                        menubar: "insert",
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "image |insert | undo redo |  formatselect | bold italic backcolor  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                      }}
                      onChange={(e, editor) => {
                        const data = e.target.getContent();
                        setEditDetail(data);
                      }}
                    />
                  </div>
                  <br /> <br />
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Tag {formData.tag} (Please, seperate each tag with comma and
                    no spaces e.g Grace ,Holiness)<Text color={"red"}>*</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    type="text"
                    placeholder="Grace,Holiness etc"
                    mb="24px"
                    fontWeight="500"
                    size="lg"
                    defaultValue={formData.tag}
                    {...register("tag", {})}
                  />
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Priority Tag<Text color={"red"}>*</Text>
                  </FormLabel>
                  <Select
                    variant="auth"
                    fontSize="sm"
                    width="100%"
                    ms={{ base: "0px", md: "0px" }}
                    type="date"
                    mb="12px"
                    defaultValue={formData.priorityTag}
                    {...register("priorityTag", { required: true })}
                  >
                    <option value={formData.priorityTag}>
                      {formData.priorityTag}
                    </option>
                    <option value={"Default"}>Default</option>
                    <option value={"Top"}>Top</option>
                  </Select>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Day of the week<Text color={"red"}>*</Text> 
                  </FormLabel>
                  <Select
                    variant="auth"
                    fontSize="sm"
                    width="100%"
                    ms={{ base: "0px", md: "0px" }}
                    type="date"
                    mb="12px"
                    defaultValue={formData.dayInTheWeek}
                    {...register("dayInTheWeek", { required: true })}
                  >
                    <option value={formData.dayInTheWeek}>
                      {getDayName(formData.dayInTheWeek)}
                    </option>
                    <option value={0}>Sunday</option>
                    <option value={1}>Monday</option>
                    <option value={2}>Tuesday</option>
                    <option value={3}>Wednesday</option>
                    <option value={4}>Thursday</option>
                    <option value={5}>Friday</option>
                    <option value={6}>Saturday</option>
                  </Select>
                  {errors.editDayInTheWeek && (
                    <small className="text-primary-red text-xs">
                      Please Enter dayInTheWeek
                    </small>
                  )}
                  {/* <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    <Text size={"xl"}>Date</Text>
                  </FormLabel> */}
                  {/* <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    width="100%"
                    ms={{ base: "0px", md: "0px" }}
                    type="date"
                    mb="12px"
                    defaultValue={"09/06/2023"}
                    {...register("date", { required: true })}
                  />
                  {errors.date && (
                    <small className="text-primary-red text-xs">
                      Please Enter Date
                    </small>
                  )} */}
                  <Button
                    fontSize="sm"
                    colorScheme="green"
                    fontWeight="500"
                    w="30%"
                    mb="24px"
                    type="submit"
                    disabled={isLoading ? true : false}
                  >
                    {isLoading ? "updating Devotional..." : "Update Devotional"}
                  </Button>
                </FormControl>
              </form>
            </Flex>
          </Card>
        </>
      ) : (
        <>
          <Card
            direction="column"
            my="60px"
            w="100%"
            px="0px"
            overflowX={{ sm: "scroll", lg: "hidden" }}
            style={{ padding: "40px 90px", alignContent: "center" }}
          >
            <Box me="auto">
              <Heading color={textColor} fontSize="36px" >
                Add Devotional
              </Heading>
              <p mb="70px">Note: Uploading a devotional for a day you have previously uploaded will result in overwriting the old one. Please use the "edit" option to update the information.</p>
              <br />
            </Box>
            <Flex
              zIndex="2"
              direction="column"
              w="100%"
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "20px", md: "auto" }}
            >
              <form
                onSubmit={handleSubmit(onSubmit)}
                method="POST"
                encType="multipart/form-data"
              >
                <FormControl>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    <Text size={"xl"}>Enter a Title</Text>
                  </FormLabel>
                  <Input
                    style={{ border: "solid 1px blue" }}
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    width="100%"
                    ms={{ base: "0px", md: "0px" }}
                    type="text"
                    mb="12px"
                    {...register("title", { required: true })}
                  />
                  {errors.title && (
                    <small className="text-primary-red text-xs">
                      Please Enter a title
                    </small>
                  )}
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    <Text size={"xl"}>Details</Text>
                  </FormLabel>
                  <div>
                    <Editor
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                      apiKey={process.env.REACT_APP_TINY}
                      initialValue={detail}
                      init={{
                        height: 500,
                        menubar: "insert",
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "image |insert | undo redo |  formatselect | bold italic backcolor  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                      }}
                      onChange={(e, editor) => {
                        const data = e.target.getContent();
                        setDetail(data);
                      }}
                    />
                  </div>
                  <br /> <br />
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Tag (Please, seperate each tag with comma and no spaces e.g
                    Grace,Holiness)<Text color={"red"}>*</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    type="text"
                    placeholder="Grace,Holiness etc"
                    mb="24px"
                    fontWeight="500"
                    size="lg"
                    {...register("tag", { required: true })}
                  />
                  {errors.tag && (
                    <small className="text-primary-red text-xs">
                      Please enter Tag
                    </small>
                  )}
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Priority Tag<Text color={"red"}>*</Text>
                  </FormLabel>
                  <Select
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    width="100%"
                    ms={{ base: "0px", md: "0px" }}
                    type="date"
                    mb="12px"
                    {...register("priorityTag", { required: true })}
                  >
                    <option value={"Default"}>Default</option>
                    <option value={"Top"}>Top</option>
                  </Select>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Day of the week<Text color={"red"}>*</Text> 
                  </FormLabel>
                  <Select
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    width="100%"
                    ms={{ base: "0px", md: "0px" }}
                    type="date"
                    mb="12px"
                    {...register("dayInTheWeek", { required: true })}
                  >
                    <option value={0}>Sunday</option>
                    <option value={1}>Monday</option>
                    <option value={2}>Tuesday</option>
                    <option value={3}>Wednesday</option>
                    <option value={4}>Thursday</option>
                    <option value={5}>Friday</option>
                    <option value={6}>Saturday</option>
                  </Select>
                  {errors.dayInTheWeek && (
                    <small className="text-primary-red text-xs">
                      Please Enter dayInTheWeek
                    </small>
                  )}
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    <Text size={"xl"}>Date</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    width="100%"
                    ms={{ base: "0px", md: "0px" }}
                    type="date"
                    mb="12px"
                    {...register("date", { required: true })}
                  />
                  {errors.date && (
                    <small className="text-primary-red text-xs">
                      Please Enter Date
                    </small>
                  )}
                  {/* <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    <Text size={"xl"}>Select the Photo</Text>
                  </FormLabel> */}
                  {/* <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    width="100%"
                    ms={{ base: "0px", md: "0px" }}
                    type="file"
                    mb="12px"
                    accept="image/*"
                    {...register("photo", { required: true })}
                  />
                  {errors.photo && (
                    <small className="text-primary-red text-xs">
                      Please Select a file to upload
                    </small>
                  )} */}
                  <Button
                    fontSize="sm"
                    colorScheme="green"
                    fontWeight="500"
                    w="30%"
                    mb="24px"
                    type="submit"
                    disabled={isLoading ? true : false}
                  >
                    {isLoading ? "Adding Devotional..." : "Add Devotional"}
                  </Button>
                </FormControl>
              </form>
            </Flex>
          </Card>
        </>
      )}
      {/* ALERT Dialog box */}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        {isSendmail ? (
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {mailTitle}
              </AlertDialogHeader>

              <AlertDialogBody>
              <List spacing={3}>
                {
                  mailContent.map( data => {
                    return (
                      <>
                      {data.title 
                      &&
                      <ListItem>
                          <ListIcon as={MdCheckCircle} color='green.500' />
                          {data.title}
                      </ListItem>
                      }
                        
                      </>
                    )
                  })
                }
                </List>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} colorScheme="red" onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="green" onClick={sendDevotionalUpdate} ml={3}>
                  Send
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        ) : (
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete News
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure? You can't undo this action afterwards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={deleteDevotional} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        )}
      </AlertDialog>
    </>
  );
}
