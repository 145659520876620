import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdMessage,
  MdPerson,
  MdLocationOn,
  MdLock,
  MdAddCard,
  MdOutlineEventRepeat,
  MdPeopleAlt,
} from "react-icons/md";

import { HiSpeakerphone, HiOutlineNewspaper } from "react-icons/hi"
import { GiHumanPyramid } from "react-icons/gi"

// Admin Imports
import Users from "views/admin/users";
import Location from "views/admin/location";
import Event from "views/admin/event";
import News from "views/admin/news";
import Press from "views/admin/press";
import Leaders from "views/admin/leaders";
import Transaction from "views/admin/transaction";
import Devotional from "views/admin/devotional"

// Auth Imports
import SignInCentered from "views/auth/signIn";
let userRoute = {};

const user = sessionStorage.getItem("user");
if (!user) {
  userRoute = {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  };

}
const routes = [
  {
    name: "Transaction",
    layout: "/admin",
    icon: <Icon as={MdAddCard} width="20px" height="20px" color="inherit" />,
    path: "/transaction",
    component: Transaction,
  },
  {
    name: "Church Leaders",
    layout: "/admin",
    icon: <Icon as={GiHumanPyramid} width="20px" height="20px" color="inherit" />,
    path: "/leaders",
    component: Leaders,
  },
  {
    name: "Locations",
    layout: "/admin",
    icon: <Icon as={MdLocationOn} width="20px" height="20px" color="inherit" />,
    path: "/location",
    component: Location,
  },
  {
    name: "Events",
    layout: "/admin",
    icon: (
      <Icon
        as={MdOutlineEventRepeat}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: "/events",
    component: Event,
  },
  {
    name: "Devotional",
    layout: "/admin",
    icon: (
      <Icon
        as={MdMessage}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: "/devotional",
    component: Devotional,
  },
  {
    name: "News",
    layout: "/admin",
    icon: (
      <Icon
        as={HiOutlineNewspaper}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: "/news",
    component: News,
  },
  {
    name: "Press Releases",
    layout: "/admin",
    icon: (
      <Icon
        as={HiSpeakerphone}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: "/press",
    component: Press,
  },
  {
    name: "Members",
    layout: "/admin",
    icon: <Icon as={MdPeopleAlt} width="20px" height="20px" color="inherit" />,
    path: "/users",
    component: Users,
  },


  userRoute,
];

export default routes;
