import { Box, SimpleGrid } from "@chakra-ui/react";
import { useState, CSSProperties } from "react";
import ComplexTable from "views/admin/press/components/ComplexTable";
import React, { useEffect } from "react";
import { useGetRequest } from "../../../mutation";
import Swal from "sweetalert2";
import Spinner from "components/Spinner";

export default function Settings() {
  let [color, setColor] = useState("#000000");
  const tableheader = [
    {
      Header: "Image",
      accessor: "photo",
    },
    {
      Header: "More",
      accessor: "_id",
    },
  ];
  const [tableData, setTableData] = useState([]);

  const { createPost, isLoading, errorResp, success } =
    useGetRequest("api/pressrelease");

  useEffect(() => {
    createPost({});
  }, [createPost]);

  useEffect(() => {
    if (success) {
      setTableData(success.data);
    }
    if (errorResp) {
      Swal.fire({
        title: "Error!",
        text: errorResp.errorResponse,
        icon: "error",
        confirmButtonText: "ok",
        confirmButtonColor: "#08AC04",
      });
    }
  }, [success, errorResp]);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
          <SimpleGrid
            mb="20px"
            columns={{ sm: 1, md: 1 }}
            spacing={{ base: "20px", xl: "20px" }}
          >
            <ComplexTable columnsData={tableheader} tableData={tableData} />
          </SimpleGrid>
        </Box>
      )}
    </>
  );
}
