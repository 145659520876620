import { Box, SimpleGrid } from "@chakra-ui/react";
import ComplexTable from "views/admin/users/components/ComplexTable";
import React, { useEffect } from "react";
import { useGetRequest } from "../../../mutation";
import Swal from "sweetalert2";
import Spinner from "components/Spinner";
import AllService from "../../../services/index";

export default function Settings() {
  const user = sessionStorage.getItem("user");
  if (!user) window.location.href = "/admin/default#/auth/sign-in";
  const tableheader = [
    {
      Header: "firstname",
      accessor: "firstname",
    },
    {
      Header: "lastname",
      accessor: "lastname",
    },
    {
      Header: "email",
      accessor: "email",
    },
    {
      Header: "gender",
      accessor: "gender",
    },
    {
      Header: "Status",
      accessor: "civil_status",
    },
    {
      Header: "country",
      accessor: "country",
    },
    {
      Header: "church",
      accessor: "church.church_name",
    },
  ];

  const [tableData, setTableData] = React.useState([]);
  const [locationData, setLocationData] = React.useState([]);
  const { createPost, isLoading, errorResp, success } =
    useGetRequest("api/user");

  useEffect(() => {
    AllService.getAllLocations()
      .then((res) => {
        if (res.data.status === 200) {
          setLocationData(res.data.data);
        }
      })
      .catch((e) => {
        Swal.fire({
          title: "Error!",
          text: "An error occured: " + e.response.data.msg,
          icon: "error",
          confirmButtonText: "ok",
          confirmButtonColor: "#08AC04",
        });
      });
  }, []);

  useEffect(() => {
    createPost({});
  }, [createPost]);

  useEffect(() => {
    if (success) {
      setTableData(success.data);
    }
    if (errorResp) {
      Swal.fire({
        title: "Error!",
        text: errorResp.errorResponse,
        icon: "error",
        confirmButtonText: "ok",
        confirmButtonColor: "#08AC04",
      });
    }
  }, [success, errorResp]);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
          <SimpleGrid
            mb="20px"
            columns={{ sm: 1, md: 1 }}
            spacing={{ base: "20px", xl: "20px" }}
          >
            <ComplexTable
              columnsData={tableheader}
              tableData={tableData}
              locationData={locationData}
            />
          </SimpleGrid>
        </Box>
      )}
    </>
  );
}
