import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Icon, SimpleGrid,
  useColorModeValue, Select, Box, Button,
} from "@chakra-ui/react";
import React, { useMemo, useRef, useState, useEffect } from "react";

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { HashLink } from 'react-router-hash-link';

// Custom components
import Card from "components/card/Card";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox"
import moment from "moment";
// Assets
import { MdDateRange, MdPayments, MdCancel, MdPerson, MdPerson2, MdPerson4, } from "react-icons/md";
import { IoCheckmarkCircle } from "react-icons/io5";
import { IoMdCash } from "react-icons/io";

import { usePostRequest } from "../../../../mutation";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import AllService from "../../../../services/index";
import Pagination from "../../../../components/Pagination";
//charts
import PieChart from "components/charts/PieChart";
// import { pieChartData, pieChartOptions } from "variables/charts";
import { VSeparator } from "components/separator/Separator";

export default function ColumnsTable(props) {
  const { columnsData, tableData, locationData } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [selectedCountry, setSelectedCountry] = useState("All");
  const [selectedLocation, setSelectedLocation] = useState("All");
  const [summaryLocation, setSummaryLocation] = useState({});
  const [summaryCountry, setSummaryCountry] = useState({});
  const [filterData, setFilterData] = useState({
    success: 0,
    failed: 0,
    totalSuccessfullAmount: 0,
    totalFailedAmount: 0,
  });

  //PIE CHART DATA 
  const [genderData, setGenderData] = useState({})
  const [countryDataArray, setCountryDataArray] = useState([])
  const [locationDataArray, setLocationDataArray] = useState([])
  const [maritalStatusData, setMaritalStatusData] = useState({})

  //get gender data
  const getGender = () => {
    const male = tableData?.filter((item) => item.gender === "Male")
    const female = tableData?.filter((item) => item.gender === "Female")
    const gender = {
      male: male.length,
      female: female.length
    }
    setGenderData(gender)
  }

  //marital Status data
  const getStatus = () => {
    const single = tableData?.filter((item) => item.civil_status === "Single")
    const married = tableData?.filter((item) => item.civil_status === "Married")
    const divorced = tableData?.filter((item) => item.civil_status === "Divorced")
    const widow = tableData?.filter((item) => item.civil_status === "Widow")
    const status = {
      single: single.length,
      married: married.length,
      divorced: divorced.length,
      widow: widow.length
    }
    setMaritalStatusData(status)
  }

  useEffect(() => {
    getGender()
    getStatus()
  }, [tableData]);



  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    state: { pageIndex },
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageCount,
  } = tableInstance;
  initialState.pageSize = 20;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  //the alert component
  const cancelRef = useRef();


  //SUMMARY BY COUNTRY
  useEffect(() => {
    if (selectedCountry === null) {
      const summaryFilter = {
        male: 0,
        female: 0,
      }
      setCountryDataArray([])
      setSummaryCountry(summaryFilter)
    } else {
      const selectedValid = async () => {
        const selected = await tableData?.filter((item) => item.country === selectedCountry)
        const male = selected?.filter((item) => item.gender === "Male")
        const female = selected?.filter((item) => item.gender === "Female")
        const summaryFilter = {
          male: male.length,
          female: female.length,
        }
        setCountryDataArray(selected)
        setSummaryCountry(summaryFilter)
      }
      selectedValid()
    }

  }, [selectedCountry])

  //GET ANALYSIS BY CHURCH LOCATION
  useEffect(() => {
    if (selectedLocation === null) {
      const summaryFilter = {
        male: 1,
        female: 0,
      }
      setSummaryLocation(summaryFilter)
      setLocationDataArray([])
    } else {
      const selectedValid = async () => {
        const selected = await tableData?.filter((item) => item.church?.church_name === selectedLocation)
        const male = selected?.filter((item) => item.gender === "Male")
        const female = selected?.filter((item) => item.gender === "Female")
        const summaryFilter = {
          male: male.length,
          female: female.length,
        }
        setSummaryLocation(summaryFilter)
        setLocationDataArray(selected)
      }
      selectedValid()
    }
  }, [selectedLocation])

  return (
    <>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, "2xl": 4 }}
        gap="20px"
        mb="20px"
        mt="40px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdPerson} color={brandColor} />
              }
            />
          }
          name="Total Registered Members"
          value={tableData.length || 0}
          url="#"
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdPerson2} color={"green.700"} />
              }
            />
          }
          name="Male Members"
          value={genderData.male || 0}
          url="#"
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdPerson4} color={"red.700"} />
              }
            />
          }
          name="Female Members"
          value={genderData.female || 0}
          url="#"
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdDateRange} color={brandColor} />
              }
            />
          }
          name="Single Members"
          value={maritalStatusData.single}
          url="#"
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdDateRange} color={brandColor} />
              }
            />
          }
          name="Married Members"
          value={maritalStatusData.married}
          url="#"
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdDateRange} color={brandColor} />
              }
            />
          }
          name="Divorced Members"
          value={maritalStatusData.divorced}
          url="#"
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdDateRange} color={brandColor} />
              }
            />
          }
          name="Widows"
          value={maritalStatusData.widow}
          url="#"
        />
      </SimpleGrid>
      {/* Users BY COUNTRY AND CHRUCH STARTS */}
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }}
        gap="20px"
        mb="20px"
        mt="40px"
      >

        <Card align='center' direction='column' w='100%' >
          <Flex justify='space-between' align='start' px='10px' pt='5px'>
            <Flex flexDirection='column' align='start' me='20px'>

              <Flex justifyContent='space-between'
                alignItems='center'>
                <Text
                  color={textColor}
                  fontSize='24px'
                  fontWeight='700'
                  lineHeight='100%'>
                  Members by country
                </Text>
              </Flex>
            </Flex>
            <Flex align='center'>
              <Select
                fontSize='sm'
                variant='subtle'
                defaultValue='All'
                width='unset'
                fontWeight='700'
                onChange={(e) => setSelectedCountry(e.target.value)}
              >
                <option value={null}>Select</option>
                <option value="Afghanistan">Afghanistan</option>
                <option value="Aland Islands">Aland Islands</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antarctica">Antarctica</option>
                <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bonaire, Sint Eustatius and Saba">Bonaire, Sint Eustatius and Saba</option>
                <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                <option value="Botswana">Botswana</option>
                <option value="Bouvet Island">Bouvet Island</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                <option value="Brunei Darussalam">Brunei Darussalam</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">Central African Republic</option>
                <option value="Chad">Chad</option>
                <option value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Congo, Democratic Republic of the Congo">Congo, Democratic Republic of the Congo</option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cote D'Ivoire">Cote D'Ivoire</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Curacao">Curacao</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Territories">French Southern Territories</option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guernsey">Guernsey</option>
                <option value="Guinea">Guinea</option>
                <option value="Guinea-Bissau">Guinea-Bissau</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="India">India</option>
                <option value="Indonesia">Indonesia</option>
                <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Isle of Man">Isle of Man</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jersey">Jersey</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                <option value="Korea, Republic of">Korea, Republic of</option>
                <option value="Kosovo">Kosovo</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macao">Macao</option>
                <option value="Macedonia, the Former Yugoslav Republic of">Macedonia, the Former Yugoslav Republic of</option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malawi">Malawi</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                <option value="Moldova, Republic of">Moldova, Republic of</option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montenegro">Montenegro</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Namibia">Namibia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherlands">Netherlands</option>
                <option value="Netherlands Antilles">Netherlands Antilles</option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau">Palau</option>
                <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Philippines">Philippines</option>
                <option value="Pitcairn">Pitcairn</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Qatar">Qatar</option>
                <option value="Reunion">Reunion</option>
                <option value="Romania">Romania</option>
                <option value="Russian Federation">Russian Federation</option>
                <option value="Rwanda">Rwanda</option>
                <option value="Saint Barthelemy">Saint Barthelemy</option>
                <option value="Saint Helena">Saint Helena</option>
                <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                <option value="Saint Lucia">Saint Lucia</option>
                <option value="Saint Martin">Saint Martin</option>
                <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                <option value="Samoa">Samoa</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Serbia">Serbia</option>
                <option value="Serbia and Montenegro">Serbia and Montenegro</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Sint Maarten">Sint Maarten</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
                <option value="South Sudan">South Sudan</option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                <option value="Swaziland">Swaziland</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                <option value="Thailand">Thailand</option>
                <option value="Timor-Leste">Timor-Leste</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Emirates">United Arab Emirates</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="United States">United States</option>
                <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                <option value="Uruguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Viet Nam">Viet Nam</option>
                <option value="Virgin Islands, British">Virgin Islands, British</option>
                <option value="Virgin Islands, U.s.">Virgin Islands, U.s.</option>
                <option value="Wallis and Futuna">Wallis and Futuna</option>
                <option value="Western Sahara">Western Sahara</option>
                <option value="Yemen">Yemen</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
              </Select>
            </Flex>
          </Flex>
          <Box h='240px' mt='auto'>
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }}
              gap="20px"
              mb="20px"
              mt="40px"
            >
              <MiniStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={
                      <Icon w="32px" h="32px" as={MdPerson} color={brandColor} />
                    }
                  />
                }
                name="Total Members"
                value={Number(summaryCountry.male) + Number(summaryCountry.female) || 0}
                url="#"
              />
              <MiniStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={
                      <Icon w="32px" h="32px" as={MdPerson2} color={"green.700"} />
                    }
                  />
                }
                name="Male"
                value={summaryCountry.male || 0}
                url="#"
              />
              <MiniStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={
                      <Icon w="32px" h="32px" as={MdPerson4} color={"red.700"} />
                    }
                  />
                }
                name="Female"
                value={summaryCountry.female || 0}
                url="#"
              />
              {summaryCountry.male === 0 && summaryCountry.female === 0 ?
                <></>
                :
                <HashLink to="/admin/users#country">
                  <Button style={{ marginTop: "1em", marginBottom: "1em", backgroundColor: "green", color: "white" }}>See list</Button>
                </HashLink>
              }
            </SimpleGrid>
          </Box>
        </Card>
        {/* USERS BY BRANCH STARTS */}
        <Card align='center' direction='column' w='100%' >
          <Flex justify='space-between' align='start' px='10px' pt='5px'>
            <Flex flexDirection='column' align='start' me='20px'>

              <Flex justifyContent='space-between'
                alignItems='center'>
                <Text
                  color={textColor}
                  fontSize='24px'
                  fontWeight='700'
                  lineHeight='100%'>
                  Members by church
                </Text>
              </Flex>
            </Flex>
            <Flex align='center'>
              <Select
                fontSize='sm'
                variant='subtle'
                defaultValue='All'
                width='unset'
                fontWeight='700'
                onChange={(e) => setSelectedLocation(e.target.value)}
              >
                <option value={null} >Select</option>
                {locationData.map((item, index) => (<option value={item.church_name} key={index} >{item.church_name}</option>)
                )}

              </Select>
            </Flex>
          </Flex>
          <Box h='240px' mt='auto'>
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }}
              gap="20px"
              mb="20px"
              mt="40px"
            >
              <MiniStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={
                      <Icon w="32px" h="32px" as={MdPerson} color={brandColor} />
                    }
                  />
                }
                name="Total Members"
                value={Number(summaryLocation.male) + Number(summaryLocation.female) || 0}
                url="#"
              />
              <MiniStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={
                      <Icon w="32px" h="32px" as={MdPerson2} color={"green.700"} />
                    }
                  />
                }
                name="Male"
                value={summaryLocation.male || 0}
                url="#"
              />
              <MiniStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg={boxBg}
                    icon={
                      <Icon w="32px" h="32px" as={MdPerson4} color={"red.700"} />
                    }
                  />
                }
                name="Female"
                value={summaryLocation.female || 0}
                url="#"
              />
              {summaryLocation.male === 0 && summaryLocation.female === 0 ?
                <></>
                :
                <HashLink to="/admin/users#location">
                  <Button style={{ marginTop: "1em", marginBottom: "1em", backgroundColor: "green", color: "white" }}>See list</Button>
                </HashLink>
              }
            </SimpleGrid>
          </Box>
        </Card>
        {/* USERS BY BRANCH ENDS */}
      </SimpleGrid>
      {/* Users BY COUNTRY AND CHRUCH ENDS */}
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" justify="space-between" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            All Members
          </Text>
        </Flex>
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px" overflowX={"auto"}>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "firstname") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "lastname") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "email") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "gender") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "Status") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "country") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "church") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor="transparent"
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Pagination
          pageIndex={pageIndex}
          pageCount={pageCount}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
        />
      </Card>
      {/* {"MEMBERS BY COUNTRY STARTS"} */}
      {summaryCountry.male === 0 && summaryCountry.female === 0 ?
        <></>
        :
        <>
          <Card
            direction="column"
            w="100%"
            px="0px"
            overflowX={{ sm: "scroll", lg: "hidden" }}
            id="country"
          >
            <Flex px="25px" justify="space-between" mb="20px" align="center">
              <Text
                color={textColor}
                fontSize="22px"
                fontWeight="700"
                lineHeight="100%"
              >
                Members from {selectedCountry}
              </Text>
            </Flex>
            <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px" overflowX={"auto"}>
              <Thead>
                {headerGroups.map((headerGroup, index) => (
                  <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {headerGroup.headers.map((column, index) => (
                      <Th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        pe="10px"
                        key={index}
                        borderColor={borderColor}
                      >
                        <Flex
                          justify="space-between"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          {column.render("Header")}
                        </Flex>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {countryDataArray.map((item, index) => (
                  <Tr key={index}>
                    <Td>
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {item.firstname}
                      </Text>
                    </Td>
                    <Td>
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {item.lastname}
                      </Text>
                    </Td>
                    <Td>
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {item.email}
                      </Text>
                    </Td>
                    <Td>
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {item.gender}
                      </Text>
                    </Td>
                    <Td>
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {item.civil_status}
                      </Text>
                    </Td>
                    <Td>
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {item.country}
                      </Text>
                    </Td>
                    <Td>
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {item.church.church_name}
                      </Text>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Card>
        </>
      }
      {/* {"MEMBERS BY COUNTRY ENDS"} */}
      {/* {"MEMBERS BY LOCATION STARTS"} */}
      {summaryLocation.male === 0 && summaryLocation.female === 0 ?
        <></>
        :
        <>
          <Card
            direction="column"
            w="100%"
            px="0px"
            overflowX={{ sm: "scroll", lg: "hidden" }}
            id="location"
          >
            <Flex px="25px" justify="space-between" mb="20px" align="center">
              <Text
                color={textColor}
                fontSize="22px"
                fontWeight="700"
                lineHeight="100%"
              >
                Members from {selectedLocation}
              </Text>
            </Flex>
            <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px" overflowX={"auto"}>
              <Thead>
                {headerGroups.map((headerGroup, index) => (
                  <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {headerGroup.headers.map((column, index) => (
                      <Th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        pe="10px"
                        key={index}
                        borderColor={borderColor}
                      >
                        <Flex
                          justify="space-between"
                          align="center"
                          fontSize={{ sm: "10px", lg: "12px" }}
                          color="gray.400"
                        >
                          {column.render("Header")}
                        </Flex>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {locationDataArray.map((item, index) => (
                  <Tr key={index}>
                    <Td>
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {item.firstname}
                      </Text>
                    </Td>
                    <Td>
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {item.lastname}
                      </Text>
                    </Td>
                    <Td>
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {item.email}
                      </Text>
                    </Td>
                    <Td>
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {item.gender}
                      </Text>
                    </Td>
                    <Td>
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {item.civil_status}
                      </Text>
                    </Td>
                    <Td>
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {item.country}
                      </Text>
                    </Td>
                    <Td>
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {item.church.church_name}
                      </Text>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Card>
        </>
      }
      {/* {"MEMBERS BY LOCATION ENDS"} */}

    </>
  );
}
