import { Box, SimpleGrid } from "@chakra-ui/react";
import ComplexTable from "views/admin/location/components/ComplexTable";
import React, { useEffect } from "react";
import { useGetRequest } from "../../../mutation";
import Swal from "sweetalert2";
import Spinner from "components/Spinner";
import axios from "axios";

export default function Settings() {
  const user = sessionStorage.getItem("user");
  if (!user) window.location.href = "/admin/default#/auth/sign-in";
  const tableheader = [
    {
      Header: "church name",
      accessor: "church_name",
    },
    {
      Header: "address",
      accessor: "adress",
    },
    {
      Header: "email",
      accessor: "email",
    },
    {
      Header: "contact",
      accessor: "main_contact",
    },
    {
      Header: "leader name",
      accessor: "chruch_leader.full_name",
    },
    {
      Header: "more",
      accessor: "_id",
    },
  ];
  const [tableData, setTableData] = React.useState([]);
  const [leaders, setLeaders] = React.useState([]);
  const { createPost, isLoading, errorResp, success } =
    useGetRequest("api/locations");

  const getLeader = async () => {
    try {
      const endpoint = "api/churchleader";
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/${endpoint}`
      );
      setLeaders(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLeader();
    createPost({});
  }, [createPost]);

  useEffect(() => {
    if (success) {
      setTableData(success.data);
    }
    if (errorResp) {
      Swal.fire({
        title: "Error!",
        text: errorResp.errorResponse,
        icon: "error",
        confirmButtonText: "ok",
        confirmButtonColor: "#08AC04",
      });
    }
  }, [success, errorResp]);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
          <SimpleGrid
            mb="20px"
            columns={{ sm: 1, md: 1 }}
            spacing={{ base: "20px", xl: "20px" }}
          >
            <ComplexTable
              columnsData={tableheader}
              tableData={tableData}
              churchLeaders={leaders}
            />
          </SimpleGrid>
        </Box>
      )}
    </>
  );
}
