import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, Select, Divider, SimpleGrid,
} from "@chakra-ui/react";
import React, { useMemo, useState, useRef, useEffect } from "react";
// import { NavLink } from "react-router-dom";

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import moment from "moment";

// Custom components
import Card from "components/card/Card";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";

// Assets
import { MdInfo, MdChurch, MdDateRange } from "react-icons/md";
import Pagination from "../../../../components/Pagination";
import Swal from "sweetalert2";
import AllService from "../../../../services/index";
import { usePostRequestWithFile } from "../../../../mutation";
import { useForm } from "react-hook-form";

export default function ColumnsTable(props) {
  const { columnsData, tableData, churchLeaders } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    state: { pageIndex },
    gotoPage,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,

    pageCount,
  } = tableInstance;
  initialState.pageSize = 15;


  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  //the alert component
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  //states
  const [details, setDetails] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedChurchLeaderId, setSelectedChurchLeaderId] = useState(null);
  const { createPost, isLoading, errorResp, success } =
    usePostRequestWithFile(`api/locations/add/${selectedChurchLeaderId}`);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();


  const onSubmit = (data) => {
    console.log(data);
    let file = data.photo[0];
    if (file.size > 10242880) {
      Swal.fire({
        title: "Error!",
        text: "File is larger than 9MB",
        icon: "error",
        confirmButtonText: "ok",
        confirmButtonColor: "#08AC04",
      });
    } else {
      delete data.photo
      createPost({ ...data, photo: file });
    }
  };

  //EDITING THE LOCATION
  const onEditSubmit = (data) => {
    // let file = data.photo[0];
    // if (file.size > 10242880) {
    //   Swal.fire({
    //     title: "Error!",
    //     text: "File is larger than 9MB",
    //     icon: "error",
    //     confirmButtonText: "ok",
    //     confirmButtonColor: "#08AC04",
    //   });
    // } else {
    //   delete data.photo
    //   createPost({ ...data, photo: file });
    // }
    console.log(data)
  };

  useEffect(() => {
    if (success) {
      setTimeout(
        Swal.fire({
          title: "New Location Added!",
          width: 600,
          padding: "3em",
          icon: "success",
          color: "#87AA62",
          background: "#fff ",
          confirmButtonColor: "#08AC04",
          backdrop: `
              #87AA62
              left top
              no-repeat
            `,
        }),
        100000
      );
      window.location.reload();
    }
    if (errorResp) {
      Swal.fire({
        title: "Error!",
        text: errorResp.errorResponse,
        icon: "error",
        confirmButtonText: "ok",
        confirmButtonColor: "#08AC04",
      });
    }
  }, [success, errorResp]);

  //selecting the selected option
  useEffect(() => {
    const name = tableData.filter((item) => item._id === selectedId)
    setDetails(name[0])
  }, [selectedId])

  // DELETE A LOCATION
  const deleteLocation = () => {
    AllService.deleteLocation(selectedId)
      .then((res) => {
        if (res.data.status === 200) {
          setTimeout(
            Swal.fire({
              title: "Church Location Deleted!",
              width: 600,
              padding: "3em",
              icon: "success",
              color: "#87AA62",
              background: "#fff ",
              confirmButtonColor: "#08AC04",
              backdrop: `
                  #87AA62
                  left top
                  no-repeat
                `,
            }),
            100000
          );
          window.location.reload();
        }
      })
      .catch((e) => {
        Swal.fire({
          title: "Error!",
          text: "An error occured: " + e.response.data.msg,
          icon: "error",
          confirmButtonText: "ok",
          confirmButtonColor: "#08AC04",
        });
      });
    onClose();
  };

  // EDIT A
  // const editLocation = () => {
  //   AllService.editLeaders(selectedId)
  //     .then((res) => {
  //       if (res.data.status === 200) {
  //         setTimeout(
  //           Swal.fire({
  //             title: "Church leader Edited!",
  //             width: 600,
  //             padding: "3em",
  //             icon: "success",
  //             color: "#87AA62",
  //             background: "#fff ",
  //             confirmButtonColor: "#08AC04",
  //             backdrop: `
  //                 #87AA62
  //                 left top
  //                 no-repeat
  //               `,
  //           }),
  //           100000
  //         );
  //         window.location.reload();
  //       }
  //     })
  //     .catch((e) => {
  //       Swal.fire({
  //         title: "Error!",
  //         text: "An error occured: " + e.response.data.msg,
  //         icon: "error",
  //         confirmButtonText: "ok",
  //         confirmButtonColor: "#08AC04",
  //       });
  //     });
  //   onClose();
  // };

  const handleSubmits = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }}
        gap="20px"
        mb="20px"
        mt="40px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdChurch} color={brandColor} />
              }
            />
          }
          name="Total Church Locations"
          value={tableData.length}
          url="#"
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdDateRange} color={brandColor} />
              }
            />
          }
          name="Today's Date"
          value={moment().format('LLLL')}
          url="#"
        />
      </SimpleGrid>
      <Card
        direction="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" justify="space-between" mb="20px" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Location
          </Text>
        </Flex>
        <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px" overflowX={{ sm: "scroll", lg: "hidden" }}>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="10px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      justify="space-between"
                      align="center"
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color="gray.400"
                    >
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "church name") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "address") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "email") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "contact") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "leader name") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "more") {
                      data = (
                        <Text color={textColor} fontSize="sm" fontWeight="700">

                          <Button
                            onClick={() => {
                              setSelectedId(cell.value);
                              onOpen();
                            }}
                          >

                            <Icon
                              w="24px"
                              h="24px"
                              me="5px"
                              color={"yellow.400"}
                              as={MdInfo}
                            />
                          </Button>
                        </Text>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor="transparent"
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <Pagination
          pageIndex={pageIndex}
          pageCount={pageCount}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
        />
      </Card>
      {/* add a Location */}
      <Card
        direction="column"
        my="60px"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
        style={{ padding: "40px 90px", alignContent: "center" }}
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="70px">
            Add a location
          </Heading>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w="100%"
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <form onSubmit={handleSubmit(onSubmit)} method="POST"
            encType="multipart/form-data">

            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Church name<Text color={"red"}>*</Text>
            </FormLabel>
            {errors.full_name && (
              <small className="text-primary-red text-xs" style={{ color: "red" }}>
                Please input churchname
              </small>
            )}
            <Input

              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              placeholder="Enter Church name ..."
              mb="12px"
              fontWeight="500"
              size="lg"
              {...register("church_name", { required: true })}
            />
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Address<Text color={"red"}>*</Text>
            </FormLabel>
            {errors.full_name && (
              <small className="text-primary-red text-xs" style={{ color: "red" }}>
                Please Enter Church Address
              </small>
            )}
            <Input

              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              placeholder="Church Address ..."
              mb="12px"
              fontWeight="500"
              size="lg"
              {...register("adress", { required: true })}
            />
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email<Text color={"red"}>*</Text>
            </FormLabel>
            {errors.full_name && (
              <small className="text-primary-red text-xs" style={{ color: "red" }}>
                Please input Email
              </small>
            )}
            <Input

              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="email"
              placeholder="Enter Church name ..."
              mb="12px"
              fontWeight="500"
              size="lg"
              {...register("email", { required: true })}
            />
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Main Contact<Text color={"red"}>*</Text>
            </FormLabel>
            {errors.full_name && (
              <small className="text-primary-red text-xs" style={{ color: "red" }}>
                Please input main Contact number
              </small>
            )}
            <Input
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              placeholder="Enter main contact"
              mb="12px"
              fontWeight="500"
              size="lg"
              {...register("main_contact", { required: true })}
            />
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Select Church Leader<Text color={"red"}>*</Text>
            </FormLabel>
            {errors.full_name && (
              <small className="text-primary-red text-xs" style={{ color: "red" }}>
                Please select a leader
              </small>
            )}
            <Select placeholder='Select option' variant="auth"
              fontSize="sm"
              mb="12px"
              fontWeight="500"
              size="lg"
              onChange={(e) => setSelectedChurchLeaderId(e.target.value)}
            >
              {churchLeaders.map((item, index) => (
                <option value={item._id} key={index}>{item.full_name}</option>
              )
              )}
            </Select>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              <Text size={"xl"}>Select the Photo</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              width="100%"
              ms={{ base: "0px", md: "0px" }}
              type="file"
              mb="12px"
              accept="image/*"
              {...register("photo", { required: true })}
            />
            <Button
              mt={20}
              fontSize="sm"
              colorScheme="green"
              fontWeight="500"
              w="30%"
              mb="24px"
              type="submit"
              disabled={isLoading ? true : false}
            >
              {isLoading ? "Adding Location..." : "Add Location"}
            </Button>

          </form>
        </Flex>
      </Card>
      {/* Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete {details?.church_name} </ModalHeader>
          <Divider orientation='horizontal' mb={4} />
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize='sm'>You cant edit this content. Deleted content are irreversible</Text>
            <Divider orientation='horizontal' mt={4} mb={4} />
            {/* 
            <form onSubmit={handleSubmit(onEditSubmit)} method="POST"
              encType="multipart/form-data">
              <FormControl>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Church name<Text color={"red"}>*</Text>
                </FormLabel>
                {errors.full_name && (
                  <small className="text-primary-red text-xs" style={{ color: "red" }}>
                    Please input Fullname
                  </small>
                )}
                <Input

                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="text"
                  mb="12px"
                  fontWeight="500"
                  size="lg"
                  {...register("church_names", { required: true })}
                />
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Address<Text color={"red"}>*</Text>
                </FormLabel>
                {errors.full_name && (
                  <small className="text-primary-red text-xs" style={{ color: "red" }}>
                    Please Enter Church Address
                  </small>
                )}
                <Input

                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="text"
                  placeholder="Enter Church Address ..."
                  mb="12px"
                  fontWeight="500"
                  size="lg"
                  {...register("adresss", { required: true })}
                />
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Email<Text color={"red"}>*</Text>
                </FormLabel>
                {errors.full_name && (
                  <small className="text-primary-red text-xs" style={{ color: "red" }}>
                    Please input Email
                  </small>
                )}
                <Input
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="email"
                  placeholder="Enter church email ..."
                  mb="12px"
                  fontWeight="500"
                  size="lg"
                  {...register("emails", { required: true })}
                />
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Main Contact<Text color={"red"}>*</Text>
                </FormLabel>
                {errors.full_name && (
                  <small className="text-primary-red text-xs" style={{ color: "red" }}>
                    Please input main Contact number
                  </small>
                )}
                <Input
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="text"
                  placeholder="Enter main contact"
                  mb="12px"
                  fontWeight="500"
                  size="lg"

                  {...register("main_contact", { required: true })}
                />
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Select Church Leader<Text color={"red"}>*</Text>
                </FormLabel>
                {errors.full_name && (
                  <small className="text-primary-red text-xs" style={{ color: "red" }}>
                    Please select a leader
                  </small>
                )}
                <Select placeholder='Select option' variant="auth"
                  fontSize="sm"
                  mb="12px"
                  fontWeight="500"
                  size="lg"

                  onChange={(e) => setSelectedChurchLeaderId(e.target.value)}
                >
                  {churchLeaders.map((item, index) => (
                    <option value={item._id} key={index}>{item.full_name}</option>
                  )
                  )}
                </Select>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  <Text size={"xl"}>Select the Photo</Text>
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  width="100%"
                  ms={{ base: "0px", md: "0px" }}
                  type="file"
                  mb="12px"
                  accept="image/*"
                  {...register("photo", { required: true })}
                />
                <Button
                  mt={20}
                  fontSize="sm"
                  colorScheme="green"
                  fontWeight="500"
                  w="30%"
                  mb="24px"
                  type="submit"
                  disabled={isLoading ? true : false}
                >
                  {isLoading ? "Adding Location..." : "Add Location"}
                </Button>
              </FormControl>
            </form> */}
          </ModalBody>
          {/* <Divider orientation='horizontal' mt={4} mb={4} /> */}
          <ModalFooter>
            <hr></hr>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={deleteLocation} ml={3}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
