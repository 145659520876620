import { Box, SimpleGrid } from "@chakra-ui/react";
import ComplexTable from "views/admin/transaction/components/ComplexTable";
import StripeTransaction from "views/admin/transaction/components/StripeTransaction";
import React, { useEffect } from "react";
import Swal from "sweetalert2";
import Spinner from "components/Spinner";
import AllService from "../../../services/index";
import { useGetRequest } from "../../../mutation";

export default function Settings() {
  const user = sessionStorage.getItem("user");
  if (!user) window.location.href = "/admin/default#/auth/sign-in";

  const [tableData, setTableData] = React.useState([]);
  const [summaryData, setSummaryData] = React.useState({});

  const { createPost, isLoading, errorResp, success } =
    useGetRequest("api/webhook/search");

  useEffect(() => {
    createPost({});
  }, [createPost]);

  useEffect(() => {
    if (success) {
      setTableData(success.data.events);
      setSummaryData(success.data);
    }
    if (errorResp) {
      Swal.fire({
        title: "Error!",
        text: errorResp.errorResponse,
        icon: "error",
        confirmButtonText: "ok",
        confirmButtonColor: "#08AC04",
      });
    }
  }, [success, errorResp]);

  const tableheader = [
    {
      Header: "name",
      accessor: "name",
    },
    {
      Header: "email",
      accessor: "email",
    },
    {
      Header: "amount",
      accessor: "amount",
    },
    {
      Header: "status",
      accessor: "status",
    },
    {
      Header: "country",
      accessor: "country",
    },

    {
      Header: "date",
      accessor: "date",
    },
  ];

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <SimpleGrid
              mb="20px"
              columns={{ sm: 1, md: 1 }}
              spacing={{ base: "20px", xl: "20px" }}
            >
              <ComplexTable
                columnsData={tableheader}
                tableData={tableData}
                summary={summaryData}
              />
            </SimpleGrid>
          </Box>
        </>
      )}
    </>
  );
}
